/* eslint no-console:0 */
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'flatpickr/dist/flatpickr.css'

import '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"

import * as Sentry from '@sentry/browser'

import '../lib/direct_uploads'
import '~/controllers'

import '../stylesheets/cce/index.sass'
import '../assets/images/cce'
import '../assets/images/shared'

import '../components/home/home'
import '../components/products_index/products_index'
import '../components/grow_and_sell/grow_and_sell'
import '../components/product_card/product_card'
import '../components/charges_show/charges_show'
import '../components/login_container/login_container'
import '../components/dashboard_page/dashboard_page'
import '../components/listing_new/listing_new'
import '../components/listings_index/listings_index'
import '../components/listing_edit/listing_edit'
import '../components/listing_form/listing_form'
import '../components/blog_posts_index/blog_posts_index'

// checkout JS
import '../components/shopping-cart/shopping-cart'
import '../components/checkout/shipping'

ActiveStorage.start()

if (window.Sentry && import.meta.env.VITE_RAILS_ENV !== 'development' && import.meta.env.VITE_RAILS_ENV !== 'test') {
  Sentry.onLoad(function() {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_JS_DSN,
      environment: import.meta.env.VITE_RAILS_ENV,
      maxBreadcrumbs: 50,
      attachStacktrace: true,
      integrations: [
        Sentry.replayIntegration(),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  });
}
